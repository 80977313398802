<template>
  <div>
    <vs-popup class="holamundo" id="receiptspopup" title="" :active.sync="open">
      <vs-table :data="receipts">
        <template slot="thead">
          <vs-th>Can-ID</vs-th>
          <vs-th>Person Name</vs-th>
          <vs-th>Transaction Type</vs-th>
          <vs-th>Cheque No</vs-th>
          <vs-th>PDC Date</vs-th>
          <vs-th>Transaction Id</vs-th>
          <vs-th>TID</vs-th>
          <vs-th>City</vs-th>
          <vs-th>Receipt No</vs-th>
          <vs-th>Amount</vs-th>
          <vs-th>Receipt Date</vs-th>
          <vs-th>MR Number</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.identity }}</vs-td>
            <vs-td>{{ tr.person_name }}</vs-td>
            <vs-td>{{ tr.transaction_type }}</vs-td>
            <vs-td>{{ tr.cheque_no }}</vs-td>
            <vs-td>{{ tr.pde_date }}</vs-td>
            <vs-td>{{ tr.transaction_id }}</vs-td>
            <vs-td>{{ tr.cc_machine_tid_no }}</vs-td>
            <vs-td>{{ tr.city }}</vs-td>
            <vs-td>{{ tr.receipt_no }}</vs-td>
            <vs-td>{{ tr.amount }}</vs-td>
            <vs-td>{{ unixTimestampConvert(tr.receipt_date) }}</vs-td>
            <vs-td>{{ tr.mr_number }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
import eventbus from "../eventbus";
export default {
  data() {
    return {
      open: false,
      receipts: [],
    };
  },
  mounted() {
    document.getElementById("receiptspopup").children[1].style.width = "90%";
    eventbus.$on("open-receipts-popup", (payload) => {
      this.receipts = payload;
      this.open = true;
    });
  },
};
</script>

<style></style>
