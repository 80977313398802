<template>
  <vs-popup
    id="duessummarypopup"
    class="holamundo"
    :title="title"
    :active.sync="open"
    style="z-index: 52001"
  >
    <vs-chip color="primary" style="margin-left: 1%; margin-bottom: 1%">
      <b>{{ data.length }}</b>
    </vs-chip>
    <vs-table v-if="open" :data="data" max-items="10" pagination>
      <template slot="thead">
        <vs-th>
          IDENTITY
        </vs-th>
        <vs-th>
          PERSON NAME
        </vs-th>
        <vs-th>
          BATCH
        </vs-th>
        <vs-th>
          SPOC
        </vs-th>
        <vs-th>
          AGREED FEES
        </vs-th>
        <vs-th>
          AMOUNT RECEIVED
        </vs-th>
        <!-- <vs-th>
          AMOUNT IN PROCESS
        </vs-th> -->
        <vs-th>
          AMOUNT PENDING
        </vs-th>
        <vs-th>
          ENROLLMENT DATE
        </vs-th>
        <vs-th>
          EXPECTED DATE
        </vs-th>
        <vs-th>
          COMMENTS
        </vs-th>
        <vs-th>
          LOAN ID
        </vs-th>
        <vs-th>
          LOAN STATUS
        </vs-th>
        <vs-th>
          ACTION
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            {{ tr.identity }}
          </vs-td>
          <vs-td>
            {{ tr.person_name }}
          </vs-td>
          <vs-td>
            {{ tr.batch }}
          </vs-td>
          <vs-td>
            {{ tr.assigned_to_name }}
          </vs-td>
          <vs-td >
            {{ numberWithCommas(tr.agreed_cost) }}
          </vs-td>
          <vs-td >
            {{ numberWithCommas(sumOfAmount(tr)) }}
          </vs-td>
          <!-- <vs-td style="text-align:right;">
            {{numberWithCommas(tr.amount_in_process) }}
          </vs-td> -->
          <vs-td >
            {{ numberWithCommas(parseInt(tr.agreed_cost) - sumOfAmount(tr)) }}
          </vs-td>
          <vs-td>
            {{ convertTimestampToDate(tr.enrollment_date) }}
          </vs-td>

          <vs-td>
            {{ convertTimestampToDate(tr.payment_expected_date) }}
          </vs-td>
          <vs-td>
            {{ tr.payment_comment }}
          </vs-td>
          <vs-td>
            {{ tr.loan_id }}
          </vs-td>
          <vs-td>
            {{ tr.loan_status }}
          </vs-td>
          <vs-td>
            <vs-row>
              <div data-tooltip="Info Popup" @click="openPopupEmit(tr)">
                <vs-icon
                  icon="info_outline"
                  size="small"
                  color="dark"
                ></vs-icon>
              </div>
              <!-- <div data-tooltip="Mark as Defaulter" v-if="status" @click="markAsDefaulter(tr)">
                <vs-icon
                  icon="swap_horizontal_circle"
                  size="small"
                  color="dark"
                ></vs-icon>
              </div> -->
              <div data-tooltip="Fetch Receipts for Purchase" @click="fetchReceiptsForPurchase(tr)">
                <!-- <vs-icon icon="receipt" size="small" color="dark"></vs-icon> -->
                <img src="https://img.icons8.com/ios-filled/18/000000/r.png" />
              </div>

              <div data-tooltip="Mark as Loan" @click="markAsLoan(tr)" v-if="tr.loan === 0">
                <!-- <vs-icon icon="receipt" size="small" color="dark"></vs-icon> -->
                <img src="https://img.icons8.com/ios-filled/18/000000/l.png" />
              </div>

              <div data-tooltip="Update Loan" @click="updateLoan(tr)" v-if="tr.loan === 1">
                <!-- <vs-icon icon="receipt" size="small" color="dark"></vs-icon> -->
                <img src="https://img.icons8.com/ios-filled/18/000000/l.png" />
              </div>

              <div data-tooltip="Payment Settings" @click="openPaymentSettings(tr)">
                <img
                  src="https://img.icons8.com/ios-filled/18/000000/settings.png"
                />
              </div>

              <div data-tooltip="Remove from loan"  @click="enableRemoveFromLoanPopup(tr)" v-if="tr.loan === 1">
                <img
                  src="https://img.icons8.com/ios-glyphs/18/000000/multiply.png"
                />
              </div>
            </vs-row>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup class="holamundo" title="Are you sure?" :active.sync="popupOpen">
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b>{{ text }}</b>
        </vs-col>
      </vs-row>
      <vs-row
        style="margin-top: 2%"
        v-if="text === 'Are you sure you want to mark this lead as a loan?'"
      >
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-input label-placeholder="Loan ID" v-model="loan_id" />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <v-select
            style="position: fixed; width: 200px; z-index: 1000; height: 20px "
            :options="loan_status_values"
            v-model="loan_status"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="popupOpen = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button
            color="success"
            type="border"
            @click="defualterRequest(defaulterPayload)"
            >Yes</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup
      class="holamundo"
      title="Are you sure?"
      :active.sync="updateLoadStatus"
    >
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b>Update Loan Status</b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-input label-placeholder="Loan ID" v-model="loan_id" />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <v-select
            style="position: fixed; width: 200px; z-index: 1000; height: 20px "
            :options="loan_status_values"
            v-model="loan_status"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="updateLoadStatus = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button
            color="success"
            type="border"
            @click="UpdateLoadForCandidate(defaulterPayload)"
            >Save</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup
      class="holamundo"
      title="Are you sure?"
      :active.sync="removeFromLoanPopup"
    >
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b> Are you sure you want to remove this cadidate from loan?</b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="removeFromLoanPopup = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button
            color="success"
            type="border"
            @click="removeFromLoan(defaulterPayload)"
            >Yes</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup
      class="holamundo"
      title="Payment Settings"
      :active.sync="settingsOpen"
    >
      <vs-row style="margin-top: 2%; margin-bottom:2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <b>Expected Date:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <datepicker
            placeholder="Select Date"
            style="z-index: 1000; position: fixed"
            :format="customFormatter"
            v-model="expected_payment_date"
          ></datepicker>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 4%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-textarea label="Comments" v-model="expected_comments" />
        </vs-col>
      </vs-row>

      <vs-collapse>
        <vs-collapse-item>
          <div slot="header">
            History
          </div>
          <div v-for="(comment, index) in commentsHistory" :key="index">
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="flex-start"
                vs-w="4"
              >
                <b>{{ getDate(comment.created_at) }}:</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="8"
              >
                {{ comment.comments }}
              </vs-col>
            </vs-row>
            <vs-divider />
          </div>
        </vs-collapse-item>
      </vs-collapse>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="settingsOpen = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button color="success" type="border" @click="saveSettings()"
            >Save</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <receiptspopup></receiptspopup>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import receiptspopup from "../pagesComponents/ReceiptsPopup.vue";
import moment from "moment";
import vSelect from "vue-select";
export default {
  data() {
    return {
      open: false,
      popupOpen: false,
      data: [],
      loan_status_values: [
        "Not created",
        "Created",
        "In Review",
        "Documentation",
        "Push for disbursal",
        "Disbursed",
        "Rejected",
        "Dropped",
      ],
      title: "",
      defaulterPayload: {},
      commentsHistory: [],
      url: "",
      settingsOpen: false,
      removeFromLoanPopup: false,
      updateLoadStatus: false,
      text: "",
      expected_payment_date: null,
      expected_comments: "",
      loan_id: "",
      loan_status: "Created",
    };
  },
  components: {
    receiptspopup,
    Datepicker,
    "v-select": vSelect,
  },
  mounted() {
    // this.data = [];
    document.getElementById("duessummarypopup").children[1].style.width = "90%";
    eventbus.$on("display-data-popup", (payload) => {
      console.log("payload", payload);
      this.data = [];
      payload[0].forEach(element => {
        console.log("payload",element);
        if(this.numberWithCommas(parseInt(element.agreed_cost) - this.sumOfAmount(element))!= 0){
          this.data.push(element);
        }
      });
      // payload[0].forEach(element => {
      //   if(payload[1] === "Overdue") {
      //     if(parseInt(element.agreed_cost)>this.sumOfAmount(element) || parseInt(element.agreed_cost)<this.sumOfAmount(element) || parseInt(element.agreed_cost) === this.sumOfAmount(element)) {
      //       console.log("payload",element);
      //       this.data.push(element);
      //     }
      //   }
      //   if(payload[1] !== "Overdue") {
      //     if(parseInt(element.agreed_cost)>this.sumOfAmount(element) || parseInt(element.agreed_cost)<this.sumOfAmount(element)) {
      //       console.log("payload",element);
      //       this.data.push(element);
      //     }
      //   }
        
      // });
      
      this.status = true;
      if (payload[1] === "Defaulter") {
        this.status = false;
      }

      if (!this.getrole("cm4")) {
        this.status = false;
      }
      this.title = payload[2];
      this.open = true;
    });
  },
  methods: {
    sumOfAmount(data) {
			// console.log("amount", data)
			// var msgTotal = data.reduce(function(prev, cur) {
				
			// }, 0);
      var msgTotal = data.amount_paid + data.amount_in_process;
			// console.log('Total Messages:', msgTotal);
			return msgTotal;
		},
    numberWithCommas(x) {
      return x.toLocaleString('en-IN');
    },
    getDate(created_at) {
      return moment(created_at).format("DD-MM-YYYY");
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      if (isNaN(ms)) {
        let str = "";
        return str;
      }
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    saveSettings() {
      console.log(this.expected_payment_date);
      let obj = {
        expected_date:
          this.expected_payment_date === null
            ? null
            : this.datatoTimestamp(this.expected_payment_date),
        comments: this.expected_comments,
        purchase_id: this.defaulterPayload.id,
      };
      console.log("saveSettings",obj);
      let url = `${constants.SERVER_API}updatePaymentInfoForPurchase`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.settingsOpen = false;
            this.data.forEach((purchase) => {
              if (purchase.id === this.defaulterPayload.id) {
                purchase.payment_comment = obj.comments;
                purchase.payment_expected_date = obj.expected_date;
              }
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    UpdateLoadForCandidate() {
      let obj = {
        purchase_id: this.defaulterPayload.id,
        loan_id: this.loan_id,
        loan_status: this.loan_status,
      };
      console.log("UpdateLoadForCandidate",obj);
      let url = `${constants.SERVER_API}updateLoanStatus`;
      console.log(url);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.updateLoadStatus = false;
            this.data.forEach((purchase) => {
              if (purchase.id === this.defaulterPayload.id) {
                purchase.loan_id = this.loan_id;
                purchase.loan_status = this.loan_status;
              }
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    customFormatter(date) {
      return moment(date).format("Do MMMM YYYY");
    },
    openPaymentSettings(payload) {
      console.log("openPaymentSettings",payload);
      this.commentsHistory = [];
      this.expected_payment_date = null;
      this.expected_comments = "";
      this.startloading();
      let url = `${constants.SERVER_API}getPaymentCommentHistory`;
      let obj = {
        purchase_id: payload.id,
      };
      console.log(obj);
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.stoploading();
          if (response.data.status === "success") {
            this.commentsHistory = response.data.data;
            this.defaulterPayload = payload;
            this.settingsOpen = true;
          } else {
            this.handleNotification(response);
          }
        })
        .catch((error) => {
          this.stoploading();
          this.handleError(error);
        });
    },
    openPopupEmit(payload) {
      this.openBigPopup(payload.mwb_id);
    },
    markAsDefaulter(payload) {
      // console.log(payload);
      this.text = "Are you sure you want to mark this lead as a defaulter?";
      this.url = "markAsDefaulter";
      this.defaulterPayload = payload;
      this.popupOpen = true;
    },
    markAsLoan(payload) {
      this.text = "Are you sure you want to mark this lead as a loan?";
      this.url = "markAsLoan";
      this.loan_id = "";
      this.loan_status = "";
      this.defaulterPayload = payload;
      this.popupOpen = true;
    },
    enableRemoveFromLoanPopup(payload) {
      this.defaulterPayload = payload;
      this.removeFromLoanPopup = true;
    },
    updateLoan(payload) {
      console.log("update loan",payload);
      this.defaulterPayload = payload;
      this.loan_status = payload.loan_status;
      this.loan_id = payload.loan_id;
      this.updateLoadStatus = true;
    },
    defualterRequest(payload) {
      console.log("payload",payload);
      let url = `${constants.SERVER_API}${this.url}`;
      let obj = {};
      if (this.url === "markAsDefaulter") {
        obj = {
          purchase_id: payload.id,
          mwb_id: payload.mwb_id,
          person_id: payload.person_id,
          identity: payload.identity,
          enrollment_id: payload.enrollment_id,
          user_id: localStorage.getItem("spoc_id"),
          action: 'defaulter_added',
        };
      } else if (this.url === "markAsLoan") {
        obj = {
          purchase_id: payload.id,
          loan_id: this.loan_id,
          loan_status: this.loan_status,
        };
      }
      console.log("defualterRequest",obj, url);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.open = false;
            this.popupOpen = false;
            eventbus.$emit("reload-dues-data");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    removeFromLoan(payload) {
      console.log("removeFromLoan",payload);
      this.startloading();
      let url = `${constants.SERVER_API}removeFromloan`;
      let obj = {
        purchase_id: payload.id,
      };
      console.log("removeFromLoan",obj, url);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.stoploading();
          this.handleNotification(response);
          this.removeFromLoanPopup = false;
          this.open = false;
          eventbus.$emit("reload-dues-data");
        })
        .catch((error) => {
          this.stoploading();
          this.handleError(error);
        });
    },
    fetchReceiptsForPurchase(purchase) {
      this.startloading();
      let url = `${constants.SERVER_API}fetchReceiptsForPurchase`;
      let obj = {
        purchase_id: purchase.id,
      };
      console.log(obj);
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "success") {
            this.stoploading();
            eventbus.$emit("open-receipts-popup", response.data.receipts);
          } else {
            this.handleNotification(response);
          }
        })
        .catch((error) => {
          this.stoploading();
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}
</style>

